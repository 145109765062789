export const couponIcon = [
  "608 134",
  `<g>
  <path d="M163.7,114.1c19.5-2.6,39.8-5.3,60.1-8.1c28.9-3.9,53.7,1.6,75.1,24.2c39.3,41.4,80.5,81,120.9,121.4
	  c27.4,27.5,27.6,52.7,0.3,80c-41.3,41.4-82.7,82.9-124.2,124.1c-25.1,24.9-51.1,24.9-76,0.1c-42.8-42.6-85.2-85.6-128.3-127.9
	  c-13.6-13.4-21.8-29.4-20-47.6c4.2-41.8,10.6-83.4,16.6-125c0.8-5.3,2.9-12,6.8-15.1c12.2-9.8,7.3-20.8,5.3-32.6
	  c-2.4-14.2-3.9-28.7-3.3-43c0.5-14,11.7-25.3,22.8-26.9c11.5-1.6,24.2,5.9,28.9,19.9C154.7,75.3,158.4,93.6,163.7,114.1z
	   M148,133.7c-25.9-0.7-43,11.8-47.8,38c-5.9,32.2-10.4,64.7-13.8,97.3c-2.3,21.3,4.6,39.9,20.9,55.6c38.1,36.8,75,74.7,112.5,112.1
	  c23.4,23.3,46.6,23.8,69.7,0.8c38.2-38,76.3-76.1,114.3-114.3c21.5-21.7,21.7-46,0.2-67.6c-39.2-39.6-78.6-79.1-118.4-118.1
	  c-6.4-6.3-15-12.1-23.6-14.2c-33.8-8.2-66.2,3.5-99.1,6.3c0,23.7,0,23.7,11,38.7c9.6,13.1,6.3,31.9-7.3,40.7
	  c-13.3,8.7-30.8,5-39.5-8.3c-9-13.8-5.5-33.8,9.7-39.7C152.4,154.9,150.6,145.4,148,133.7z M149.4,115.4
	  c-4.3-18-7.2-34.6-12.6-50.3c-1.9-5.6-10-9.1-15.3-13.6c-3.8,5.4-10.8,10.9-10.7,16.3c0.3,17.9,3.1,35.7,5.1,54.5
	  C128.1,119.9,137.2,118,149.4,115.4z M143.8,170.4c-8.9,4.4-12.7,12.5-6.8,23c5.1,9.2,14.2,11.3,22.9,5.7c9.9-6.4,10.4-15.4,4.2-24
	  c-4.9,4.1-9.3,7.9-13.8,11.6C148.4,181.9,146.4,177,143.8,170.4z"/>
  <path d="M254,352.9c-7.4-35.6-14.7-70.3-21.9-104.9c-4.2-20.1-7.1-40.6-12.7-60.3c-3.8-13.4,0.1-21.4,12.6-28.5
	  c5.6,26.4,11.1,52.2,16.5,78c6,28.8,10.9,57.9,18.1,86.3C270,336.7,267,345,254,352.9z"/>
  <path d="M327.8,259.3c-0.1,15.4-14.5,30.3-29.3,30.2c-16.3,0-35.9-20.9-35.4-37.7c0.5-14.7,14.6-29,28.8-29.4
	  C308.9,222.1,327.9,241.7,327.8,259.3z M291,235.6c-5,8.3-10.1,14.2-9.1,15.5c5.3,7.1,11.9,13.2,18.7,18.8c0.5,0.4,9.6-7.7,9.2-8.5
	  C305.1,253.6,299.2,246.5,291,235.6z"/>
  <path d="M158.2,252c0.3-15.7,14.6-29.9,29.8-29.5c16.2,0.4,34.9,20,34.9,36.6c0.1,14.9-14.9,30.4-29.4,30.4
	  C176.9,289.6,157.9,269.3,158.2,252z M210.7,260.5c-11-8.1-18.1-14-25.9-18.5c-1.1-0.6-5.9,5.2-9,8.1c6.3,6.7,12.3,13.9,19.4,19.7
	  C196.3,270.7,202.3,265.7,210.7,260.5z"/>
</g>
`,
];