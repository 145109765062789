export const scooter = [
  "608 134",
  `
	<g>
		<path d="M458.4,303.1c-24.1-27.2-52.4-37.8-85.6-25.7c-31.5,11.5-47.5,35.9-49.6,70.3c-42.4,0-84.9,0-127.9,0
			c0.4,19.9-6.3,36-21.1,48.4c-10.8,9-23.3,13-37.2,13c-29.3-0.1-57.9-24.1-57-61.1c-8.5,0-17.1,0-25.9,0c-2.4-27,0.1-52.4,15.1-75.1
			c20.3-30.7,48.8-46.9,85.6-47.2c31.2-0.2,62.4-0.1,93.6-0.1c2.2,0,4.3,0,7.3,0c0,31.7,0,62.8,0,95c7.4,0,14.1,0,20.8,0
			c6,0,12-0.3,18,0.1c4.4,0.3,6.2-1.4,7.6-5.5c10.2-29.1,29.5-49.9,57.9-61.6c8.2-3.4,17.1-5.1,26.3-7.8c-0.1-0.9-0.2-2.5-0.6-3.9
			c-9.9-35.4-20-70.7-29.8-106.2c-1.3-4.5-3.2-6-7.9-5.8c-7.8,0.4-15.6,0.1-24.2,0.1c0-8.3-0.1-16.3,0.2-24.2c0-0.9,2.5-2.5,3.8-2.5
			c14.2-0.2,28.4,0,42.6-0.2c4.3,0,4.6,3.1,5.4,5.9c11.8,41.5,23.9,83,35.1,124.7c2.6,9.5,6.2,14.6,16.3,16.9
			c9.5,2.2,18.2,7.9,27,12.7c2,1.1,3.8,4.6,3.9,7.1C458.7,280.8,458.4,291.2,458.4,303.1z M81,320c49.8,0,98.7,0,147.7,0
			c0-22.7,0-45,0-67.8c-2.6,0-4.7,0-6.9,0c-21.8,0-43.6,0-65.4,0c-27.1,0-48.1,11.7-63.6,33.8C85.8,296,81.7,307.2,81,320z
			M107.8,348c-1.3,13.1,3.8,22.7,14.3,29.2c10.8,6.8,22.2,6.5,32.7-0.7c9.7-6.6,14.6-16,13.1-28.6C147.9,348,128.2,348,107.8,348z"
			/>
		<path d="M458.5,351.2c1.9,27.7-22.3,57.9-57.2,57.7c-31.9-0.1-57-25.5-57-57.5c0-32.5,25.1-57.7,57.1-58.1
			C434.3,292.9,460.4,322.7,458.5,351.2z M401.8,381.8c16.9-0.1,30.2-13.7,30.1-30.9c-0.1-16.4-13.9-30.4-30.2-30.6
			c-16.6-0.2-31.1,14.5-30.9,31.3C371.1,368.4,384.8,381.9,401.8,381.8z"/>
		<path d="M182.5,177.7c24.5,0,48.4,0,72.9,0c0,9,0,17.8,0,27c-24.3,0-48.4,0-72.9,0C182.5,195.7,182.5,187,182.5,177.7z"/>
	</g>
`,
];