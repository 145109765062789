export const faqIcon = [
  "608 134",
  `
	<path d="M385,116.9c-22.3-30.6-53.5-51.1-87.7-60.3v0c-7.9-8.8-16.9-16.9-26.9-24.2C194.5-22.7,87.9-5.9,32.5,70.1
	c-44.7,61.7-42.8,144.7,2.8,203.6l0.4,61.5c0,1.5,0.2,3,0.7,4.4c2.5,7.8,10.8,12.2,18.6,9.7l58.7-18.5c15.5,5.5,31.6,8.7,47.5,9.6
	l-0.2,0.2c41.3,30.1,95.5,39.1,145.2,22.7l59,19.2c1.5,0.5,3,0.7,4.6,0.7c8.2,0,14.8-6.6,14.8-14.8v-62.2
	C425.4,250.7,426.4,174,385,116.9z M120.1,297.8l-5.6-2.3l-45.9,14.4l-0.5-48.2l-3.7-4.2C25.2,209.6,22.6,140.6,59.4,90
	c44.7-61.3,130.4-74.9,191.6-30.6c61.3,44.6,74.9,130.1,30.6,191.1C244.4,301.5,177.9,320.3,120.1,297.8z M354.4,289.9l-3.7,4.6
	l0.5,48.2l-45.5-15.3l-5.6,2.3c-26,9.6-53.7,10.4-79.3,3.2l-0.1,0c34.3-10.5,65.4-31.9,87.8-62.6c35.4-48.8,41.2-110.2,20.6-162.5
	l0.3,0.2c10.7,7.7,20.5,17.2,28.8,28.8C391.7,183,389.8,245.9,354.4,289.9z"/>
	<g>
	<path d="M118.9,132.4v13.7H90.3v14.8h21.4v13.3H90.3v28.4H73.2v-70.2H118.9z"/>
	<path d="M171.6,190.2h-26.2l-4.2,12.4h-17.9l25.4-70.2h19.8l25.4,70.2h-18.1L171.6,190.2z M167.2,177l-8.7-25.7l-8.6,25.7H167.2z"
		/>
	<path d="M251.6,214.6l-9-12.1c-2.7,0.5-5.3,0.8-7.8,0.8c-6.6,0-12.7-1.5-18.2-4.6c-5.5-3.1-9.9-7.3-13.1-12.8
		c-3.2-5.5-4.9-11.7-4.9-18.5c0-6.9,1.6-13,4.9-18.5c3.2-5.5,7.6-9.7,13.1-12.8s11.5-4.6,18.2-4.6c6.6,0,12.6,1.5,18.1,4.6
		s9.8,7.3,13,12.8c3.2,5.5,4.8,11.6,4.8,18.5c0,6-1.2,11.5-3.6,16.5c-2.4,5-5.8,9.1-10.2,12.3l15.4,18.5H251.6z M221.3,182.2
		c3.4,3.7,7.9,5.5,13.6,5.5c5.6,0,10.1-1.9,13.4-5.6c3.4-3.7,5.1-8.7,5.1-14.8c0-6.2-1.7-11.2-5.1-14.9c-3.4-3.7-7.8-5.5-13.4-5.5
		c-5.7,0-10.2,1.8-13.6,5.5c-3.4,3.7-5,8.6-5,14.9C216.2,173.5,217.9,178.5,221.3,182.2z"/>
	</g>
`,
];