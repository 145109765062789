//import axios from "axios";
import { responsiveFontSizes } from "@material-ui/core";
// import { Console } from "console";
import { useState } from "react";
const ApiName = () => {
  const axios = require('axios');
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const LoginUser = async (data, url, method) => {
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      // if (!response.ok) {
      //   throw new Error("Sorry something went wrong");
      // }
      const results = await response.json();
      setLoading(false);
      return results;
    } catch (error) {
      setLoading(false);
    }
  };

  const AddUser = async (data, url, method) => {
    const response = await axios(url, {
      method: method,
      headers: {
        "x-access-token": token,
         "Content-Type": "multipart/form-data",
      },
      body: data,
    });
    const results = await response.data;
    return results;
  };

  const ResetPassword = async (data, url, method) => {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const results = await response.json();
    return results;
  };

  const ForgotPassword = async (url, method , obj) => {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body : JSON.stringify(obj)
    });
    const results = await response.json();
    return results;
  };

  const VerifyEmailOtp = async (url, method , obj) => {
    
  };

  const UpdatePassword = async (data, url, method) => {
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(data),
      });
      // if (!response.ok) {
      //   throw new Error(`Error! status: ${response.status}`);
      // }
      const results = await response.json();
      return results;
    } catch (err) {
      // console.log(err);
    }
  };

  const GetContent = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/getAllContacts",
        {
          method: "POST",
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const results = await response.json();
      // console.log("results0000000", results);
      setLoading(false);
      return results;
    } catch (err) {
      // console.log(err);
    }
  };

  const ProfileImage = async (data, url, method) => {
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(data),
      });
      // if (!response.ok) {
      //   throw new Error(`Error! status: ${response.status}`);
      // }
      const results = await response.json();
      return results;
    } catch (err) {
      // console.log(err);
    }
  };

  const GetCmsPage = async () =>{
    try {
      setLoading(true);
      const response = await fetch(
          process.env.REACT_APP_BASE_URL_ADMIN + "/getcmspage",
          {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(key),
          }
      );
      const results = await response.json();
      return results;
    }
    catch(err){
        // console.log("err",err);
    }
  }

  const GetCms = async (lang , urlKey)=>{
    try{
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BASE_URL_ADMIN +`/${urlKey}?lang=${lang}`,
          {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
            },
          }
      );
      const results = await response.json();
      return results;
    }
    catch(e){
      console.log(e)
    }
  }

  // const singinUser = async (data, url) => {
  //   try {
  //     const results = await axios.post(url, data);
  //     console.log("resdfffff=====>", results);
  //     return results;
  //   } catch (err) {
  //     if (err.response) {
  //     } else if (err.request) {
  //     } else {
  //     }
  //   }
  // };

  // const LoginUser = async (data, url) => {
  //   console.log(url, data);
  //   // setLoading(true);
  //   console.log(url, data);
  //   const results = await axios
  //     .post(url, data)
  //     .then((results) => {
  //       console.log(results);
  //     })
  //     .catch((error) => {
  //       console.log(error.results);
  //     });
  //   // return results;
  //   // setLoading(false);
  // };

  return {
    LoginUser,
    AddUser,
    ResetPassword,
    ForgotPassword,
    UpdatePassword,
    GetContent,
    ProfileImage,
    loading,
    GetCmsPage,
    GetCms,
    VerifyEmailOtp
  };
};

export default ApiName;
