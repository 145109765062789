import React from "react";
import {
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import ApiName from "src/ApiName";
import { NotificationManager, } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useEffect } from "react";
import queryString from 'query-string'
import { useLocation , useParams } from 'react-router-dom'

const RideScooters = () => {
  const { search } = useLocation()
  const {lang} = queryString.parse(search)

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const { GetCms } = ApiName()

  useEffect(async () => {
    const results = await GetCms(lang , 'ride-scooters')
    if (results.status == 200) {
      setTitle(results.data.title)
      setContent(results.data.content)
    }
    else {
      NotificationManager.error(results.message);
    }

  }, []);
  return (
    <CContainer>
      <CRow>
        <CCol>
          <h1 className="text-left mb-3 text-black">{title}</h1>
          {/* <h4 className="text-left mb-2">{title}</h4> */}
          <p dangerouslySetInnerHTML={{__html: content}}></p>

        </CCol>
      </CRow>
    </CContainer>
  );
};

export default RideScooters;