export const payments = [
  "608 134",
  `
<g>
<path d="M328.6,192.1c39,0,78-0.1,117,0c28.2,0.1,45.8,17,46.1,45.3c0.5,50.4,0.4,100.7,0,151.1c-0.2,26.3-17.7,43.9-44,44
  c-80.7,0.3-161.4,0.3-242.1,0c-26.2-0.1-44.1-17.5-44.4-43.6c-0.5-50.9-0.5-101.8-0.1-152.7c0.2-27.2,18.8-44.2,47.3-44.3
  c40.1-0.1,80.2,0,120.2,0C328.6,192,328.6,192,328.6,192.1z M468.6,281.9c-95.1,0-187.4,0-281,0c0,35-0.1,68.5,0,102.1
  c0,17.8,4,22.1,21.8,22.3c34.1,0.3,68.3,0.3,102.4,0.3c44.5,0,88.9,0,133.4-0.1c10.7,0,22.7-0.8,23-14.6
  C469.2,355.6,468.6,319.4,468.6,281.9z M468.5,239.8c0.8-14.3-5.2-21.3-18.7-21.6c-8.1-0.2-16.3,0-24.4,0
  c-72.1,0-144.1-0.1-216.2,0.1c-16.7,0-20.9,4.7-20.4,21.6C281.8,239.8,374.8,239.8,468.5,239.8z"/>
<path d="M352.2,165.3c-48.4,0-93.3,0.3-138.1-0.1c-55.1-0.4-80.6,24.6-80.7,79.9c0,36.8-0.5,73.7,0.2,110.5c0.3,18,2.8,36,4.4,54.4
  c-17.7,3.9-35.1-3.5-44.6-22.2c-23-45.4-45.7-90.8-67.9-136.6c-12.1-25-4.5-47.7,20.3-60c71.6-35.7,143.4-71.1,215.4-106.1
  c25.7-12.5,48-4.6,61.4,20.7C332.6,124.3,341.4,143.3,352.2,165.3z"/>
<path d="M301.8,361.6c-0.2,19.9-20.6,32.9-39.4,23.9c-7.2-3.5-11.4-3.4-18.6,0.1c-12.2,5.9-24.7,3.7-33.5-7.6
  c-8.7-11.1-8.8-23.3,0.4-34.2c9.1-10.8,20.6-12,33.8-7.1c5,1.9,12,2,16.9,0C281.3,328.6,301.9,340.9,301.8,361.6z"/>
</g>
`,
];
