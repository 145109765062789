export const riyalCurrency = [
  "608 134",
  `<path d="M255.4,469.7c-117.7-0.4-212.8-96.2-212.5-213.8c0.4-117.1,96.6-213.1,213-212.5c119.8,0.6,213.5,95.8,213,216.6
  C468.5,375.4,372.1,470.1,255.4,469.7z M449.1,256.2c-0.3-106.6-86.9-193.1-193.2-193C148.6,63.4,62.7,150.6,63,259.1
  c0.3,104.5,88.1,190.9,193.7,190.8C362.8,449.8,449.5,362.6,449.1,256.2z"/>
<path d="M185.3,127.7c3,2.1,5.5,3.1,5.5,3.9c-0.8,10.1,7.1,13,13.8,16.3c5.8,2.8,7.7,6.6,5.3,12.2c-3,7-6.7,13.8-9.7,20.9
  c-0.9,2.1-0.8,4.9-0.4,7.2c3.8,22.8,8.5,45.6,11.5,68.5c1.7,12.7,1.9,25.9,0.3,38.5c-1,8-5.2,16.7-10.7,22.7
  c-19.7,21.3-44.7,28-72.8,22.1c-17.4-3.7-28.1-15.9-29.9-33.3c-2.9-28.9,7.6-53.7,24.8-76.8c2.5,1.5,4.6,2.9,4.5,2.8
  c-5.4,15.7-11.6,30.1-15.2,45.2c-3.2,13.7,3.7,23.8,17.2,27.4c19,5,37.5,2.8,54.5-7.5c9-5.5,14.3-13.8,13.1-24.4
  c-2.5-20.7-5.8-41.3-9-61.9c-1.7-11-3.7-22-6.1-32.9c-0.8-3.6-3.4-6.8-4.9-10.3c-1-2.2-2.4-4.9-1.9-6.9
  C178.2,150.4,181.7,139.6,185.3,127.7z"/>
<path d="M239.6,139.1c11.6,14.8,13.2,30.4,13.8,46.2c0.8,19.7,0.7,39.5,2.3,59.2c1.1,13.8,4.4,15.7,18.2,15.7c19.4,0,38.9,0,61.6,0
  c-4.5-8.4-7.3-15.4-11.6-21.2c-3.3-4.5-4.2-8-2.1-13.1c2.8-6.8,4.9-13.9,7.7-22.1c9.9,10.7,14.5,22.4,15.2,35.3
  c0.9,16.3,0.6,32.6,1.2,49c0.2,5.5-2.1,6.8-7.1,6.7c-24.1-0.2-48.1,0-72.2-0.1c-11.2-0.1-20.1-5-22.9-15.9
  c-3.2-12.1-5.2-24.6-6-37.1c-1-14.8,0.6-29.9-0.7-44.7c-0.7-8.1-5-15.9-7.7-23.8c-0.7-2-2.1-4.4-1.5-6
  C231.2,158.3,235.2,149.5,239.6,139.1z"/>
<path d="M390.4,212.3c13.1,13.6,17.2,29,17.6,45.7c0.3,12.6,0.5,25.2-0.2,37.8c-0.3,5.5-1.6,12-4.9,16c-9,11.3-18.8,22.1-29.2,32
  c-9.9,9.3-22.4,10.6-34.4,4.8c-14.8-7.2-29-15.7-44-23.9c2.3-3.8,3.5-5.9,4.8-8c1.3,0.5,2.1,0.7,2.8,1c3.7,2.1,7.3,4.5,11.2,6.4
  c14.2,6.7,28.5,7.5,41.4-2.1c11.6-8.7,22.9-18.1,33.1-28.4c12.7-12.8,13.9-21.2,3.2-35.7c-7.5-10.2-8.4-19.6-5-30.7
  C388.1,222.8,388.9,218.3,390.4,212.3z"/>
<path d="M239.5,315.5c6.6,3.5,12.3,6.5,18.5,9.8c3.6-7,6.9-13.2,10.4-20c7.8,4.3,15,8.2,23.2,12.6c-4.3,8.1-8.4,15.8-12.8,24.1
  c-6.6-3.1-12.7-5.9-19.3-8.9c-3.7,6.9-7.2,13.4-11,20.4c-7.7-4.3-14.8-8.3-22.7-12.6C230.5,332.4,234.8,324.4,239.5,315.5z"/>
`,
];