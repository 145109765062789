import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import AboutUs from "./views/pages/cms/AboutUs";
import PrivacyPolicy from "./views/pages/cms/PrivacyPolicy";
import ContactUs from "./views/pages/cms/ContactUs";
import TermAndCondition from "./views/pages/cms/TermAndCondition";
import RideScooters from "./views/pages/cms/RideScooters";
import MapGuide from "./views/pages/cms/MapGuide";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const AuthLayout = React.lazy(() => import("./containers/AuthLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/ResetPassword/ResetPassword")
);

const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);

const VerifyEmailOtp = React.lazy(() =>
  import("./views/pages/forgotPassword/VerifyEmailOtp")
);

const PasswordChanged = React.lazy(() =>
  import("./views/pages/forgotPassword/PasswordChanged")
);


const App = () => {
  return (
    <Router>
      <Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <AuthLayout {...props} />}
          />
          <Route exact path="/register" name="Register Page">
            <Register />
          </Route>
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/passwordReset"
            name="passwordReset"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="ForgotPassword"
            render={(props) => <ForgotPassword {...props} />}
          />

          <Route
            exact
            path="/verifyEmailOtp"
            name="VerifyEmailOtp"
            render={(props) => <VerifyEmailOtp {...props} />}
          />

          <Route
            exact
            path="/passwordChanged"
            name="PasswordChanged"
            render={(props) => <PasswordChanged {...props} />}
          />

          <Route
            exact
            path="/about-us"
            name="About Us"
            render={(props) => <AboutUs {...props} />}
          />

          <Route
            exact
            path="/privacy-policy"
            name="Privacy Policy"
            render={(props) => <PrivacyPolicy {...props} />}
          />

          <Route
            exact
            path="/contact-us"
            name="Contact Us"
            render={(props) => <ContactUs {...props} />}
          />

          <Route
            exact
            path="/term-and-condition"
            name="Term and Condition"
            render={(props) => <TermAndCondition {...props} />}
          />

          <Route
            exact
            path="/ride-scooters"
            name="Ride Scooters"
            render={(props) => <RideScooters {...props} />}
          />

          <Route
            exact
            path="/map-guide"
            name="Map Guide"
            render={(props) => <MapGuide {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />

          <Route component={(props) => <AuthLayout {...props} />} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
